<template>
    <div class="history-order">
        <div class="container">
<!--            <router-link :to="{ name: 'history'}" class="go-back">
                Повернутися до історії замовлень
            </router-link>-->
          <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
            Повернутися
          </a>
            <div :class="['history-order__wrapp',{'disabled':documentDisabled}]">
                <div class="history-order__item">
                    <div class="history-order__head">
                        Страховий продукт
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Компанія</label>
                                <input type="text" class="form-control" v-model="order.companyInfo.name" disabled>
                            </div>
                            <div class="col-md-4">
                                <label>Франшиза, грн</label>
                                <input type="text" class="form-control" v-model="order.offer.franchise" disabled>
                            </div>
<!--                            <div class="col-md-4">-->
<!--                                <label>Тариф</label>-->
<!--                                <input type="text"  class="form-control" v-model="order.insuranceObject.vin" disabled>-->
<!--                            </div>-->
                        </div>
                    </div>
<!--                    <div v-if="order.dgo" class="history-order__form-group">
                        <div class="history-order__form-heading">ДЦВ:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Вартість, грн</label>
                                <input  disabled v-model="order.dgo.price" class="form-control date-selector">
                            </div>
                            <div class="col-md-4">
                                <label>Компенсація, грн</label>
                                <input  disabled v-model="order.dgo.compensation" class="form-control date-selector">
                            </div>
                        </div>
                    </div>-->
                </div>

                <div class="history-order__item">
                    <div class="history-order__head">
                        Розрахунок
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Дата дії договору:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Початок</label>
                                <input  v-if="skeletonOff" disabled v-model="order.contract.startedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Закінчення</label>
                                <input v-if="skeletonOff"  disabled v-model="order.contract.finishedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Розрахунковий період</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="orderPeriod" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Тип ТЗ (категорія):</div>
                        <div class="row">
                            <div class="col-md-8">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showAutoCategory()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Місце реєстрації власника ТЗ:</div>
                        <div class="row">
                            <div class="col-md-8 form-group">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showCityRegister()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="history-order__notification col-md-8">
                                <div class="history-order__notification__wrapp">
                                    <div class="history-order__notification__icon">
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 23.4C15.7583 23.4 18.4035 22.3043 20.3539 20.3539C22.3043 18.4035 23.4 15.7583 23.4 13C23.4 10.2417 22.3043 7.59647 20.3539 5.64609C18.4035 3.69571 15.7583 2.6 13 2.6C10.2417 2.6 7.59647 3.69571 5.64609 5.64609C3.69571 7.59647 2.6 10.2417 2.6 13C2.6 15.7583 3.69571 18.4035 5.64609 20.3539C7.59647 22.3043 10.2417 23.4 13 23.4ZM13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13C26 20.1799 20.1799 26 13 26ZM11.7 18.2H14.3V20.8H11.7V18.2ZM11.7 5.2H14.3V15.6H11.7V5.2Z" fill="#FA9A13"/>
                                        </svg>
                                        <b>Важливо</b>
                                    </div>
                                    <p>Місце реєстрації (проживання) власника транспортного засобу (фізичної особи) або місцезнаходження юридичної особи відповідно до документа про реєстрацію транспортного засобу</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item history-order__client">
                    <div class="history-order__head">
                        Дані страхувальника
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="row">
                            <div class="col-md-4">
                               	<label>{{isLegalType() ? 'ЄДРПОУ' : 'ІПН' }}</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insurant.documentation.uin" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Дата народження</label>
                                <input v-if="skeletonOff" v-model="order.insurant.dateBirthAt" class="form-control date-selector" placeholder="" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Прізвище</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.surname" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div :class="isLegalType() ? 'col-md-12' : 'col-md-4'">
                                <label>Ім'я</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.name" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>По батькові</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.patronymic" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Контактна інформація:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Телефон</label>
                                <input v-if="skeletonOff" type="text" placeholder="Телефон" class="form-control" v-model="order.insurant.phone" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Email</label>
                                <input v-if="skeletonOff" type="text" placeholder="Email" class="form-control" v-model="order.insurant.email" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Документ, що засвідчує особу:</label>
                                <input v-if="skeletonOff" type="text" class="form-control" :value="showTypeDocument()" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Запис №</label>
                                <input v-if="skeletonOff" type="text" placeholder="Запись №" class="form-control" v-model="order.insurant.documentation.serial" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="!this.isDocumentERetiree(order.insurant.privilegeTypeId)" class="col-md-4">
                                <label>Документ №</label>
                                <input v-if="skeletonOff" type="text" placeholder="Документ №" class="form-control" v-model="order.insurant.documentation.number" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>{{ !this.isDocumentERetiree(order.insurant.privilegeTypeId) ? 'Дата выдачі' : 'Закінчення дії' }}</label>
                                <input v-if="skeletonOff" v-model="order.insurant.documentation.dateOfIssue" class="form-control date-selector" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-8">
                                <label>Орган, що видав</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insurant.documentation.issuedBy" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item">
                    <div class="history-order__head">
                        Дані автомобіля
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Держ. номер</label>
                                <input v-if="skeletonOff" type="text" placeholder="Держ. номер" class="form-control" v-model="order.insuranceObject.number" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Номер кузова (VIN-код)</label>
                                <input v-if="skeletonOff" type="text" placeholder="Номер кузова (VIN-код)" class="form-control" v-model="order.insuranceObject.vin" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Автомобіль:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Модель</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insuranceObject.brand" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Марка</label>
                                <input v-if="skeletonOff" type="text"  class="form-control" v-model="order.insuranceObject.model" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Рік випуску авто</label>
                                <input v-if="skeletonOff" type="text"  class="form-control" v-model="order.insuranceObject.year" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item">
                    <div class="history-order__head">
                        Інше
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Примітки:</label>
                                <textarea v-if="skeletonOff" class="form-control" disabled></textarea>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group border-none m-b-none">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Створити на підставі даного договору новий договір:</label>
                                <select v-if="skeletonOff" class="form-control" disabled>
                                    <option value="1">Зелена картка</option>
                                </select>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__price">
                    <div class="row">
                        <div class="col-md-8">
<!--                            <div v-if="showPriceDgo()" class="history-order__price__item">
                                <p>В тому числі ДЦВ:</p>
                                <b> {{ showPriceDgo() }} <span>грн</span></b>
                            </div>-->
                            <div class="history-order__price__item">
                                <p>Платіж:</p>
                                <b> {{ showPrice() }} <span>грн</span></b>
                            </div>
                            <div v-if="showDiscountPrice()" class="history-order__price__item">
                                <p>З урахуванням знижки:</p>
                                <b> {{ showDiscountPrice() }} <span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__nav">
                    <div class="row">

                    </div>
                </div>

                <div v-if="this.loadData" class="partner-order__nav">
                    <div class="row">
                        <div class="col-md-8" v-if="order.edit">
                            <div class="row">
                                <div v-if="order.statusId == 1" class="col-md-6">
                                    <button type="button" @click="declare(order)" class="btn-blue-w">Редагувати і заявити</button>
                                </div>
                                <div v-if="order.statusId == 5" class="col-md-6">
                                    <button :disabled="isСoncludeProcess" type="button" @click="declare(order,false,false,2)" class="btn-blue-w">
                                        <span v-if="isСoncludeProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span v-else>Продовжити</span>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" @click="draft()" class="btn btn-dark">Переглянути чернетку</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button :disabled="isCopyProcess" type="button" @click="copy(order)" class="btn btn-success">
                                <span v-if="isCopyProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>Копіювати</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { calculate } from '../../api/offer';
const FileDownload = require('js-file-download');
import { showOrder, concludeOrder } from '../../api/order';
import { customerMixin, HistoryMixin } from "../../mixin";
import {contractGreenCard} from "@/api/contractGreenCard";
import Vue from "vue";
import {list as calculateGreenCard} from "@/api/greenCardInsuranceCompany";

export default {
    components: {Multiselect,contractGreenCard},
    mixins: [customerMixin, HistoryMixin],
    data: function () {
        return {
            loadMoreOptions: {},
            loadData: false,
            skeletonOff: false,
            isCopyProcess: false,
            isСoncludeProcess: false,
            togglerVisible: true,
            documentDisabled: true,
            orderPeriod: '1 рік',
            periodOptions: [
                {"id": 15, "count":"15", "key":"d", "name" : "15 д" },
                {"id": 1, "count":"1", "key":"m", "name" : "1 міс" },
                {"id": 2, "count":"2", "key":"m", "name" : "2 міс" },
                {"id": 3, "count":"3", "key":"m", "name" : "3 міс" },
                {"id": 4, "count":"4", "key":"m", "name" : "4 міс" },
                {"id": 5, "count":"5", "key":"m", "name" : "5 міс" },
                {"id": 6, "count":"6", "key":"m", "name" : "6 міс" },
                {"id": 7, "count":"7", "key":"m", "name" : "7 міс" },
                {"id": 8, "count":"8", "key":"m", "name" : "8 міс" },
                {"id": 9, "count":"9", "key":"m", "name" : "9 міс" },
                {"id": 10, "count":"10", "key":"m", "name" : "10 міс" },
                {"id": 11, "count":"11", "key":"m", "name" : "11 міс" },
                {"id": 12, "count":"12", "key":"m", "name" : "12 міс" },
            ],
            order: {
                offer: {},
                contract: {},
                companyInfo: {},
                insuranceObject: {},
                insurant:{
                    documentation: {},
                }
            }
        };
    },

     async created() {
        this.order = await showOrder(this.$route.params.orderId).then((response) => {
            return response.data.data;
        });

         this.loadData = true;
         this.skeletonOff = true;

         this.getContractPeriod();
     },


    mounted () {
        this.getContractPeriod();
    },

    methods: {
/*        showPriceDgo() {
            return parseFloat((this.order?.dgo?.price || 0));
        },*/
        /*async copy() {
            this.isCopyProcess = true;

            let periodId = this.order.offer.period?.id;
            let startDateGreen = this.getStartDateForCopy(this.order);

            let paramsGreenCard = {
                carType: this.order.insuranceObject.autoCategory,
                country: this.order.offer.country.id,
                periodOption: periodId,
                startDate: startDateGreen,
                userType: this.order.insurant.customerTypeId
            }

            let responseGreen = await calculateGreenCard(paramsGreenCard.country, paramsGreenCard.carType, paramsGreenCard.userType, paramsGreenCard.startDate, paramsGreenCard.periodOption);

            let offer = this.$_.find(responseGreen?.data?.data, (item) => {
                return item.company.id === this.order.companyInfo.companyId;
            });
            this.order.offer.dateFromMax = offer?.dateFromMax;
            this.order.offer.available_documents = offer?.available_documents ?? null;
            if (!offer?.offerId) {
                this.isCopyProcess = false;
                let userTypes= this.$store.getters.CUSTOMER_TYPES

                let paramsGreenCardSearch = {
                    dateFromMax: offer?.dateFromMax,
                    carType: carType,
                    country: this.order.offer.country,
                    periodOption: this.periodOptions.find((item) => item.id === periodId),
                    startDate: startDateGreen,//moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)).format('DD.MM.YYYY'),
                    userType: userTypes.find(option => option.id === this.order.offer.userType),
                }

                let greenCardInfoUser = {
                    documentType: this.order.insurant.documentation.type,
                    identificationCode: this.order.insurant.documentation.uin,
                    internationalPassport: (this.order.insurant.documentation.documentType == 2) ? this.order.insurant.documentation.number : null,
                    erdpyCode: this.order.insurant.documentation.uin,
                    name: this.order.insurant.name,
                    surname: this.order.insurant.surname,
                    dateBirth: this.order.insurant.dateBirthAt,
                    phone: this.order.insurant.phone,
                    mail: this.order.insurant.email,
                    city: this.order.insurant.city,
                    street: this.order.insurant.street,
                    house: this.order.insurant.house,
                    apartment: this.order.insurant.apartment,

                    // new
                    documentation: {
                        type: this.order.insurant.documentation.documentType,
                        serial: this.order.insurant.documentation.serial,
                        number: this.order.insurant.documentation.number,
                        issuedBy: this.order.insurant.documentation.issuedBy,
                        dateOfIssue: this.order.insurant.documentation.dateOfIssue,
                    },// new
                    passportSerial: this.order.insurant.documentation.serial,
                    passportNumber: ((this.order.insurant.documentation.documentType == 4)) ? this.order.insurant.documentation.number : null,
                    passportCompany: this.order.insurant.documentation.issuedBy,
                    passportDate: this.order.insurant.documentation.dateOfIssue,
                    withoutIdentificationCode: this.order.insurant.documentation.withoutUin,
                    withoutDocuments: this.order.insurant.documentation.withoutDocuments
                };

                this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', paramsGreenCardSearch);
                this.$store.commit('SET_GREENCARD_INFO_USER', greenCardInfoUser);
                this.$router.push({name: `greenCardResults`, query: {from: 'history'}});

                Vue.set(this.loadMoreOptions, this.order.id + this.order.typeId, false);

                return
            }




            this.declare(true);
        },*/
        draft() {
            window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.$route.params.orderId}`);
        },
        /*conclude() {
            this.isСoncludeProcess = true;
            let data = {
                orderId:this.order.id,
            };
            contractGreenCard(data).then((response) => {
                this.isСoncludeProcess = false;
                if (response?.data?.status === 'success' && !!response.data.data[0].contractId) {
                    this.$alert("Зелена картка вдало оформлена!", '',);
                    this.$router.push({name: "history"});
                    this.$emit('init-orders-list');
                    return true;
                }

                this.$alert("Зелена Картка не оформлена", '', 'error');
            }).catch((error) => {
                this.isСoncludeProcess = false;
                if (error?.response?.data?.status == 'error') {
                    this.$alert("Зелена Картка не оформлена", '', 'error');
                }
                else if (error?.response?.data?.data?.orderId[0]?.includes('недостатньо коштів')) {
                    this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                } else {
                    this.$alert("Зелена Картка не оформлена", '', 'error');
                }
            });
        },*/
        showPrice() {
            let price = parseFloat(this.order.price);

            if (isNaN(price)) {
                return 0;
            }

            return price.toFixed(2);
        },
        showDiscountPrice() {
            if (this.order.discountPrice) {
                let price = parseFloat(this.order.discountPrice);

                if (isNaN(price)) {
                    return 0;
                }

                return price.toFixed(2);
            }

            return 0;
        },
        /*async declare(isCopy = false) {

            let startDateGreen = this.getStartDateForCopy(this.order);

            let finishedAt = null;
            let userTypeList = this.$store.getters.CUSTOMER_TYPES;
            let autoTyprsList = this.$store.getters.AUTO_TYPES_LIST;
            this.order.offer.customerType = this.order.insurant.customerTypeId;
            let searchInfo = {
                carType: autoTyprsList.find((item) => item.external_id == this.order.insuranceObject.autoCategory),
                userType: userTypeList.find(option => option.id === this.order.offer.userType),
                country: {id: this.order.offer.country.id,
                    name: this.order.offer.country.name,
                    name_ru: this.order.offer.country.name},
                startDate: startDateGreen, //order.offer.startDate,
                periodOption: this.order.offer.period,
            }

            let infoUser = {
                documentType: this.order.insurant.documentation.documentType,
                identificationCode: this.order.insurant.documentation.uin,
                erdpyCode: this.order.insurant.documentation.uin,
                name: this.order.insurant.name,
                surname: this.order.insurant.surname,
                dateBirth: this.order.insurant.dateBirthAt,
                phone: this.order.insurant.phone,
                mail: this.order.insurant.email,
                city: this.order.insurant.city,
                street: this.order.insurant.street,
                house: this.order.insurant.house,
                apartment: this.order.insurant.apartment,
                // new
                documentation: {
                    type: this.order.insurant.documentation.documentType,
                    serial: this.order.insurant.documentation.serial,
                    number: this.order.insurant.documentation.number,
                    issuedBy: this.order.insurant.documentation.issuedBy,
                    dateOfIssue: this.order.insurant.documentation.dateOfIssue,
                    endDateOfIssue: this.order.insurant.documentation.endDateOfIssue,
                },

                internationalPassport: (this.order.insurant.documentation.documentType == 2) ? this.order.insurant.documentation.number : null,
                passportSerial: this.order.insurant.documentation.serial,
                passportNumber: ((this.order.insurant.documentation.documentType == 4)) ? this.order.insurant.documentation.number : null,
                passportCompany: this.order.insurant.documentation.issuedBy,
                passportDate: this.order.insurant.documentation.dateOfIssue,
                withoutIdentificationCode: this.order.insurant.documentation.withoutUin,
                withoutDocuments:this.order.insurant.documentation.withoutDocuments
            };

            let carInfo = {
                vin: this.order.insuranceObject.vin,
                year: this.order.insuranceObject.year,
                brand: this.order.insuranceObject.brand,
                model: this.order.insuranceObject.model,
                number: this.order.insuranceObject.number,
                city: {
                    id:this.order.insuranceObject.registrationPlaceId,
                    name:this.order.insuranceObject.registrationPlace,
                    name_full_name_ua:this.order.insuranceObject.registrationPlace
                },
                withoutVin: this.order.insuranceObject.withoutVin
            };

            this.order.offer.company = this.order.companyInfo.info;
            this.order.offer.autoCategoryType = this.order.insuranceObject.autoCategory;
            this.order.offer.company.id = this.order.offer.company.company_id;
            this.order.offer.company.logo = process.env.VUE_APP_ADMIN_DOMAIN+this.order.offer.company.logo;
            this.order.offer.companyNamePublic = this.order.companyInfo.publicName;
            this.order.offer.offerId = this.order.offer.id;
            if(isCopy){
                this.isCopyProcess = false;
            }
            this.$store.commit('CLEAR_GREENCARD_FULL_ORDER');
            this.$store.commit('SET_GREENCARD_INFO_OFFER', this.order.offer);
            this.$store.commit('SET_GREENCARD_SEARCH_PARAMS', searchInfo);
            this.$store.commit('SET_GREENCARD_INFO_USER', infoUser);
            this.$store.commit('SET_GREENCARD_INFO_CAR', carInfo);
            this.$store.commit('SET_GREENCARD_FULL_ORDER', (isCopy) ? null : this.order.id);
            this.$store.commit('SET_GREENCARD_CONTRACT_ID', this.order.contractId);
            //this.$router.push({name: 'greenCardFinnaly', query: {from: 'history'}});
            this.$router.push({name: 'greenCardSteps', query: {from: 'history'}});

        },*/

        showTypeDocument() {
            if (this.order.insurant?.documentation?.type) {
                return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: this.order.insurant.documentation.type })?.name;
            }
        },
        showAutoCategory() {
            if (this.order.insuranceObject.autoCategory) {
                return `(${this.order.insuranceObject.autoCategory})`;
            }
        },

        showCityRegister() {
            if (this.order.insurant.city) {
                return this.order.insurant.city;
            }
            /*if (this.order.insurant.city) {
                return this.order.offer.city.name_full_name_ru;
            }*/
        },
        euroStatus(value){
            $('[data-toggle="tooltip"]').tooltip('hide');
            if(this.euroChecked === true){
                this.city = value;
                $('.city-select').addClass('disabled');
            }else{
                this.city = null;
                $('.city-select').removeClass('disabled');
            }
        },
        isLegalType() {
            //вынести это в миксин
          return this.order.insurant.customerTypeId === 2;
        },
        choseCity(value) {
            this.city = value;
            $(".tooltip").remove();
            this.euroChecked = false;
            $('.params-city-status').removeClass('disabled');
        },
        getContractPeriod(){
            let currentYear = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('YYYY'));
            let currentMonth = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('MM'));
            let currentDay = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('DD'));
            let startYear = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('YYYY'));
            let startMonth = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('MM'));
            let startDay = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('DD'));

            let diffYears = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'years', true);
            let diffMonths = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'months', true);
            let diffDays = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'days', true);


            if(diffYears >= 1){
                let count = Math.round(diffYears);
                if(count >= 2){
                    this.orderPeriod = count+' роки'
                }else if(count >= 5){
                    this.orderPeriod = count+' років'
                }else{
                    this.orderPeriod = count+' рік'
                }
            }else if(diffDays > 15){
                let count = Math.ceil(diffMonths);
                if(count < 5 && count > 1){
                    this.orderPeriod = count+' місяці'
                }else if(count >= 5){
                    this.orderPeriod = count+' місяців'
                }else{
                    this.orderPeriod = count+' місяць'
                }
            }else{
                let count = diffDays;
                if(count <= 15){
                    this.orderPeriod = '15 днів'
                }else{
                    this.orderPeriod = count+' днів'
                }
            }
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

